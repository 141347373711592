import { Routes, Route } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import Nav from "./nav/Nav";
import NavCreator from "./nav/nav_creator/NavCreator";
import NavExpert from "./nav/nav_expert/NavExpert";
import NavAdmin from "./nav/nav_admin/NavAdmin";

const HeaderRoutes = () => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );

  const isAdmin = currentUser?.role === "admin";
  const isCreator = currentUser?.role === "creator";
  const isExpert = currentUser?.role === "expert";



  return (
    <Routes>
      {isExpert && <Route path="*" element={<NavExpert />} />}
      {isAdmin && <Route path="*" element={<NavAdmin />} />}
      {isCreator && <Route path="*" element={<NavCreator />} />}
      {!currentUser?.id && <Route path="*" element={<Nav />} /> }
    </Routes>
  );
};

export default HeaderRoutes;
